import React, { useRef, useEffect, useState } from 'react'
import BrushImage from './images/brush.png'
import { useDrag, Vector2 } from '@use-gesture/react'
import Video from './Video'
import { motion, AnimatePresence } from 'framer-motion'
import Zink from './images/zink.jpg'
type Point = Vector2
const distanceBetween = (point1: Point, point2: Point) =>
  Math.sqrt(
    Math.pow(point2[0] - point1[0], 2) + Math.pow(point2[1] - point1[1], 2)
  )

const angleBetween = (point1: Point, point2: Point) =>
  Math.atan2(point2[0] - point1[0], point2[1] - point1[1])

const height = 300
const width = 300

const stride = 5

const Scratch = () => {
  const [scratched, setScratched] = useState(false)
  const canvas = useRef<HTMLCanvasElement>(null)
  const ctx = useRef<CanvasRenderingContext2D | null>()
  const brush = useRef(new Image())
  const [open, setOpen] = useState(false)
  useEffect(() => {}, [])
  useDrag(
    ({ active, xy: pos, delta: movement, first, initial, memo, last }) => {
      if (!canvas.current || !ctx.current) return
      if (first) {
        const boundingClientRect = canvas.current.getBoundingClientRect()
        return boundingClientRect
      }
      if (active) {
        const boundingClientRect = memo as DOMRect
        const xy: Vector2 = [
          pos[0] - boundingClientRect.left,
          pos[1] - Math.round(boundingClientRect.top)
        ]
        const before: Vector2 = [xy[0] + movement[0], xy[1] + movement[1]]
        const distance = distanceBetween(before, xy)
        const angle = angleBetween(before, xy)
        const scale = canvas.current?.height / canvas.current?.offsetHeight
        ctx.current.globalCompositeOperation = 'destination-out'
        for (let i = 0; i < distance; ++i) {
          const x = before[0] + Math.sin(angle) * i
          const y = before[1] + Math.cos(angle) * i

          ctx.current.drawImage(brush.current, x * scale - 25, y * scale - 25)
        }
        ctx.current.globalCompositeOperation = 'source-over'
      } else if (last) {
        const { data: pixels } = ctx.current.getImageData(0, 0, width, height)

        const l = pixels.length
        const total = l / (stride * 4)
        let count = 0

        // Iterate over all pixels
        for (let i = 3; i < l; i += stride * 4) {
          if (Number(pixels[i]) === 0) {
            count++
          }
        }
        if (count / total > 0.5) setScratched(true)
      }
      return memo
    },
    { target: canvas }
  )
  useEffect(() => {
    if (canvas.current) {
      ctx.current = canvas.current.getContext('2d')
      if (ctx.current) {
        ctx.current.fillStyle = '#a1a1aa'
        // give the font some time to load
        setTimeout(() => {
          const image = new Image()
          image.src = Zink
          image.onload = () => {
            if (ctx.current) {
              ctx.current.globalAlpha = 0.7
              ctx.current?.drawImage(image, 0, 0, width, height)
              ctx.current.globalAlpha = 1
              ctx.current.font = '30px gotham'
              ctx.current.fillStyle = 'white'
              const x = 140
              ctx.current.fillText('JETZT', 100, x)
              ctx.current.fillText('FREIRUBBELN!', 40, x + 35)
            }
          }
        }, 800)
      }
      ctx.current?.fillRect(0, 0, canvas.current.width, canvas.current.height)
      brush.current.src = BrushImage
    }
  }, [])

  const codeDE = '1DLIJ3'
  const codeEN = 'HCTGVD'
  return (
    <div className="mt-20">
      <div className="lg:max-w-3xl mx-auto relative">
        <Video src="/videos/60_GIF.mp4" />
        <div className="absolute  top-2 left-1/2 bottom-0 transform -translate-x-1/2 grid place-items-center place-content-center cursor-pointer">
          <button
            onClick={() => {
              if (window.navigator.clipboard.writeText) {
                window.navigator.clipboard.writeText(codeDE)
                setOpen(true)
                setTimeout(() => {
                  setOpen(false)
                }, 3000)
              }
            }}
            className="text-4xl font-bold py-2 mb-4">
            DE: {codeDE}
          </button>
          <button
            onClick={() => {
              if (window.navigator.clipboard.writeText) {
                window.navigator.clipboard.writeText(codeEN)
                setOpen(true)
                setTimeout(() => {
                  setOpen(false)
                }, 3000)
              }
            }}
            className="text-4xl font-bold py-2">
            EN: {codeEN}
          </button>
        </div>
        <canvas
          ref={canvas}
          width={width}
          height={height}
          className={`absolute block top-0 left-1/2 h-full aspect-square bottom-0 transform -translate-x-1/2 touch-none cursor-coin transition-opacity ${
            scratched ? 'opacity-0 pointer-events-none' : ''
          }`}
        />
      </div>

      <AnimatePresence>
        {open && (
          <motion.div
            className="fixed bottom-0 left-0 right-0 z-20"
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}>
            <div
              className="flex items-center bg-[#607848] text-white text-sm font-bold px-4 py-3 "
              role="alert">
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20">
                <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
              </svg>
              <p>Gutschein kopiert!</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Scratch
