import React from 'react'

const Footer: React.FC = () => {
  return (
    <div className="grid place-content-center text-center gap-3 pt-9 mt-10 mb-12 border-t-2 border-[#2c2c2c] lg:grid-flow-col lg:gap-12">
      <span className="font-bold">© 2021 COSALUX GmbH</span>
      <a
        href="https://www.cosalux.com/kontakt/#impanchor"
        target="_blank"
        rel="noreferrer">
        Impressum
      </a>
      <a
        href="https://www.cosalux.com/datenschutzerklaerung"
        target="_blank"
        rel="noreferrer">
        Datenschutzerklärung
      </a>
    </div>
  )
}

export default Footer
