import React, { useRef } from 'react'
import Header from './components/Header'
import Text from './components/Text'
import Video from './components/Video'
import Kira from './components/Kira'
import Footer from './components/Footer'
import Scratch from './components/Scratch'
import Vimeo from '@u-wave/react-vimeo'

const PortfolioLink: React.FC<{ link: string }> = ({ link }) => {
  const text = link.replace('https://www.cosalux.com', '')
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="block hover:underline underline-offset-2 hover:decoration-[#607848]"
    >
      <p className="first-letter:text-[#607848] block">ᐅ {text}</p>
    </a>
  )
}
const App = () => {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <div className="font-serif">
      <Header />
      <Kira />
      <button
        onClick={() => {
          ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }}
        className="text-6xl block mx-auto mb-20"
      >
        <img
          className="h-8 lg:h-16"
          src={require('./components/images/scrollen.png')}
        />
      </button>
      <h2
        className="uppercase ml-4 lg:m-auto lg:text-center font-bold text-3xl lg:text-5xl max-w-2xl mb-10 lg:mb-14"
        ref={ref}
      >
        LUST AUF {"'"}NE KLEINE REISE DURCH UNSER JAHR 2021?
      </h2>
      <div className="px-4 grid lg:grid-cols-[1fr_1.5fr] items-end gap-4 lg:-mb-3 lg:grid-flow-col-dense max-w-7xl lg:mx-auto">
        <div className="relative after:block after:pb-[56.25%]">
          <Vimeo
            className="absolute inset-0"
            video="659044990"
            showTitle={false}
            dnt
            showPortrait={false}
            showByline={false}
            responsive
          />
        </div>
        <div className="lg:col-start-1">
          <Video src="/videos/58_GIF.mp4" />
        </div>
      </div>
      <div className="lg:w-1/2 relative">
        <img
          className="ml-auto lg:transform -translate-x-[19px] lg:translate-x-[3px]"
          src={process.env.PUBLIC_URL + '/Kurve_01.svg'}
        />
      </div>
      <div className="relative">
        <div className="grid pl-4 pr-12 lg:pr-4 lg:grid-cols-2 gap-2 lg:gap-x-12 lg:gap-y-4 items-center justify-items-end grid-flow-row-dense">
          <Text title="Deutsche Börse group visitors center">
            <p>
              Die Marke Deutsche Börse erlebbar machen, ohne sich aufzudrängen!
              Das war die Idee! Wir haben die Inhalte und das Ausstellungsdesign
              konzipiert, App und Multi-Touch-Anwendungen designt und
              programmiert. Wir hoffen, dich 2022 zur verschobenen Eröffnung
              begrüßen zu dürfen. Sei gespannt.
            </p>
            <PortfolioLink link="https://www.cosalux.com/portfolio-projekte/gruppe-deutsche-boerse-visitors-center-coming-soon/" />
          </Text>
          <Video src="/videos/18_GIF.mp4" />
          <Text title="Frankfurter Gartenlust">
            <p>
              Wie haben sich die Parks in der Stadt entwickelt und wie werden
              diese genutzt? Unsere Antwort darauf war eine interaktive und
              spielerische Anwendung auf einem 75” großen Medientouchtisch.
              Weitere Infos:
            </p>
            <PortfolioLink link="https://www.cosalux.com/portfolio-projekte/frankfurt-gartenlust-ausstellung-im-historischen-museum/" />
          </Text>
          <Video src="/videos/16_GIF.mp4" />

          <Text title="Hybrid-x auf der hypermotion 2021">
            <p>
              Ziel des Vorhabens ist die Schaffung eines innovativen, modularen
              Ökosystems, das die digitale und physische Welt von
              Veranstaltungen verbindet und damit den gesamten Geschäftsbereich
              Messe/ Veranstaltungen für die Zukunft sichert. Wie uns das
              gelungen ist, siehst du hier:
            </p>
            <PortfolioLink link="https://www.cosalux.com/portfolio-projekte/hybrid-x/" />
          </Text>
          <Video src="/videos/27_GIF.mp4" />
          <Text title="WALKING TOUR">
            <p>
              Wir haben eine App entwickelt, mit der Touristen interessante Orte
              jederzeit selbständig, flexibel und interaktiv erkunden und sich
              dank unserer Mixed Reality Technologie in eine neue oder alte Welt
              versetzen können. Mehr zum Projekt erfährst du hier:
            </p>
            <PortfolioLink link="https://www.cosalux.com/portfolio-projekte/walking-tour/" />
          </Text>
          <Video src="/videos/08_GIF.mp4" />

          <Text title="MOMEM Museum of modern electronic music">
            <p>
              Als weltweit erstes Museum für elektronische Musik wird das MOMEM
              ab 2022 in Frankfurt die Geschichte einer Kultur erzählen. Seit
              2016 begleiten wir den Friends of MOMEM e. V. als digitaler
              Partner und Berater. Im April 2022 ist es dann soweit und die
              erste Ausstellung mit und von Sven Väth lässt nach vorne und
              zurück blicken in das Schaffen eines echten Frankfurter Künstlers:
              “It{"'"}s simple to tell what saved us from Hell”. Mehr zu unserer
              Zusammenarbeit hier:
            </p>
            <PortfolioLink link="https://www.cosalux.com/portfolio-projekte/museum-of-modern-electronic-music-digitale-partnerschaft/" />
          </Text>
          <Video src="/videos/06_GIF.mp4" />
        </div>

        <svg
          viewBox="0 0 2 2"
          className="w-1 absolute inset-0 h-full left-full lg:left-1/2 transform -translate-x-6 lg:-translate-x-1/2"
          preserveAspectRatio="none"
        >
          <path
            strokeWidth={4}
            d="M0.5 0 0.5 2"
            className="text-[#607848] "
            stroke="currentColor"
          />
        </svg>
      </div>
      <div className="lg:w-1/2">
        <img
          className="ml-auto lg:transform -translate-x-[20px] lg:translate-x-[2px]"
          src={process.env.PUBLIC_URL + '/Kurve_02.svg'}
        />
      </div>
      <div className="grid pt-4 pl-4 pr-12 lg:pr-4 lg:grid-cols-2 gap-8 lg:gap-x-12 lg:gap-y-4 items-center justify-items-end grid-flow-row-dense">
        <Text
          title="Unser Weihnachts­geschenk für dich, Deine Familie und Freunde"
          hidden
        >
          <p>
            Wir hoffen, dass du auch an der COSALUX Tour 2022 Tour teilnehmen
            wirst! Bis dahin wünschen wir viel Vergnügen mit unserem Präsent.
          </p>
          <p>-Bitte freirubbeln!-</p>
        </Text>
      </div>

      <Scratch />
      <div className="grid pt-4 pl-4 pr-12 lg:pr-4 lg:grid-cols-2 gap-8 lg:gap-x-12 lg:gap-y-4 items-center justify-items-end grid-flow-row-dense">
        <Text title="" hidden>
          <p>
            Dein persönlicher Walking Tour Gutschein Code für die Tour
            &quot;Frankfurts Neue Altstadt&quot; - auf Deutsch und Englisch.
          </p>
          <ol className="list-decimal pl-6">
            <li>
              Lade die Walking Tour App herunter
              <br />
              <div className="grid lg:grid-cols-2 pt-2 pb-4 gap-4">
                <a href="https://walking-tour.onelink.me/5pTO/21f66b81">
                  <img
                    className="max-h-14"
                    src={require('./components/images/google-play-badge.png')}
                  />
                </a>
                <a href="https://walking-tour.onelink.me/5pTO/21f66b81">
                  <img
                    className="max-h-14 "
                    src={require('./components/images/app-store-badge.png')}
                  />
                </a>
              </div>
            </li>
            <li>Gib im Reiter “Profil” deinen Gutschein Code ein </li>
            <li>
              Die “Frankfurts Neue Altstadt” - Tour ist jetzt in deiner
              Bibliothek verfügbar! Es kann losgehen.
            </li>
          </ol>
          <p>Viel Spaß beim Entdecken!</p>
        </Text>
      </div>
      <Footer />
    </div>
  )
}

export default App
