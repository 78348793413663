import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import KiraText from './KiraText'

const useText = (id: string, name: string, last: string) => {
  const [text, setText] = useState('')
  useEffect(() => {
    if (id && KiraText[id]) {
      setText(
        atob(KiraText[id])
          .replace(/\(Vorname\)/g, name)
          .replace(/\(Nachname\)/g, last)
      )
    }
  }, [id])
  return text
}
const Kira = () => {
  const { text, name, lastname } = useParams()
  const saveText = useText(text || '', name || '', lastname || '')
  if (!saveText) {
    return null
  }
  return (
    <div className="lg:max-w-7xl mx-auto mb-8 relative after:block after:pb-[56.25%]">
      <iframe
        width="100%"
        height="100%"
        scrolling="no"
        className="absolute inset-0 overflow-hidden"
        src={'https://cosalux.net/kira/christmas/V1/main.html?text=' + saveText}
      />
    </div>
  )
}

export default Kira
