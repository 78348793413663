import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path=":text">
          <Route path=":name">
            <Route path=":lastname">
              <Route path=":greeting" element={<App />}></Route>
              <Route index element={<App />}></Route>
            </Route>
            <Route index element={<App />}></Route>
          </Route>
          <Route index element={<App />}></Route>
        </Route>
        <Route path="*" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
