import React from 'react'
import Logo from './images/logo.png'

const Header: React.FC = () => {
  return (
    <header className="grid place-items-center lg:grid-cols-[max-content_1fr_max-content] lg:grid-rows-2 grid-flow-row-dense text-xl lg:text-4xl font-bold text-center gap-8 px-5 mb-10 mt-10 lg:mt-20 max-w-7xl lg:mx-auto">
      <img
        src={Logo}
        className="h-48 hidden lg:block invisible lg:row-span-2"></img>{' '}
      <img src={Logo} className="h-48 lg:col-start-3 lg:row-span-2"></img>
      {/* <h1>
        {greeting || 'HoHoHo'}
        {name ? ` ${name}` : null}, COSALUX wünscht dir Frohe Weihnachten & nen’
        Guten Rutsch ins neue Jahr!
      </h1> */}
      <h2 className="text-lg lg:text-2xl block uppercase">
        ** Für mehr feeling, Ton einschalten! **
      </h2>
    </header>
  )
}

export default Header
