import React from 'react'
interface TextProps {
  title: string
  hidden?: boolean
}
const Text: React.FC<TextProps> = ({ title, children, hidden }) => {
  return (
    <div className="max-w-lg space-y-4 lg:even-type:col-start-2 lg:even-type:first:after:bg-red-500 group lg:even-type:justify-self-start">
      <h1
        className={`uppercase hyphens-auto lg:px-10 font-bold text-xl lg:text-4xl relative after:h-[44px] after:w-[44px] after:absolute after:transform-gpu after:right-0 after:translate-x-[48px] after:top-0 after:-translate-y-[0px] ${
          hidden ? 'after:hidden' : 'after:block'
        } lg:group-even-type:after:left-0 lg:after:translate-x-[47px] lg:group-even-type:after:-translate-x-[45px] after:bg-point after:z-10 bg-contain`}>
        {title}
      </h1>
      <div className="lg:px-10 text-base lg:text-lg space-y-4 ">{children}</div>
    </div>
  )
}

export default Text
