import React from 'react'

interface Props {
  src: string
}
const Video: React.FC<Props> = ({ src }) => {
  return (
    <video
      src={process.env.PUBLIC_URL + src}
      className="aspect-[15/10] lg:justify-self-start lg:even-type:justify-self-end"
      muted
      loop
      autoPlay
      playsInline
    />
  )
}

export default Video
